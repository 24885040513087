import React, { Fragment } from "react"
import { Breadcrumb, Container, Row, Col } from "react-bootstrap"
import bannerImg from "../assets/images/partnership-banner.jpg"
import { v4 as uuidv4 } from "uuid"
import LayoutAnimate from "../components/animation/LayoutAnimate"
import { Parallax } from "react-parallax"
import img1 from "../assets/images/how/1.svg"
import img2 from "../assets/images/how/2.png"
import img4 from "../assets/images/how/4.svg"
import img5 from "../assets/images/how/5.svg"
import img6 from "../assets/images/how/6.png"
import img7 from "../assets/images/how/7.svg"

const howData = [
  {
    id: uuidv4(),
    num: 1,
    img: img1,
    text: "Product and Market Knowledge",
  },
  {
    id: uuidv4(),
    num: 2,
    img: img2,
    text: "Assistance in Choosing Formulations",
  },
  {
    id: uuidv4(),
    num: 3,
    img: img1,
    text: "Product Development",
  },
  {
    id: uuidv4(),
    num: 4,
    img: img4,
    text: "Support with product and safety Information",
  },
  {
    id: uuidv4(),
    num: 5,
    img: img5,
    text: "Brand Development, Design and Copywriting",
  },
  {
    id: uuidv4(),
    num: 6,
    img: img6,
    text: "Assistance in Choosing packaging",
  },
  {
    id: uuidv4(),
    num: 7,
    img: img7,
    text: "Logistics",
  },
]

const About = () => {
  return (
    <Fragment>
      <LayoutAnimate>
        <section className="page-banner-section">
          <Parallax
            bgImage={bannerImg}
            bgImageAlt="contact section"
            strength={200}
            className="cta-section about-banner-section"
            renderLayer={percentage => (
              <div
                style={{
                  position: "absolute",
                  background: `linear-gradient(to right, rgba(2, 23, 32,0.9),rgba(2, 23, 32,0.2))`,
                  left: "0",
                  top: "0",
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
          >
            <div className="page-banner-wrapper">
              <Container
                className="page-banner-title-container"
                data-sal-duration="1000"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="200"
              >
                <h2>Partnership</h2>
              </Container>
              <div className="breadcrumnb-wrapper">
                <Container
                  className="breadcrumb-container pb-3"
                  data-sal-duration="1000"
                  data-sal="slide-up"
                  data-sal-easing="ease"
                  data-sal-delay="400"
                >
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Partnership</Breadcrumb.Item>
                  </Breadcrumb>
                </Container>
              </div>
            </div>
          </Parallax>
          {/* <div className="bg-img-box">
          <img src={bannerImg} alt="product banner image" />
        </div> */}
        </section>
        <section className="how-section layout-padding">
          <Container>
            <div className="heading-container heading-center ">
              <h2>Access to strategic resources that help build your brand</h2>
            </div>
            <Row className="justify-content-center">
              {howData.map(el => (
                <Col md={6} xl={4} key={el.id} className="how-col">
                  <div className="how-box">
                    <div className="num">0{el.num}</div>
                    <div className="img-box">
                      <img src={el.img} alt="" />
                    </div>
                    <h5>{el.text}</h5>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </section>
        <section className="layout-padding2-bottom">
          <Container>
            <div
              className="partnership-detail-container"
              data-sal-duration="1000"
              data-sal="slide-up"
              data-sal-easing="ease"
            >
              <h3 className="section-heading mb-4">
                PRIVATE LABEL AND CUSTOM BLENDING LUBRICANTS
              </h3>
              <p>
                Being the set up private-label lubricant accomplice for more
                than 14 years, Shan Petro centers around the course of joint
                effort, giving you the edge, it takes to succeed. Over 14 years
                of assembling and selling lubricants implies we have
                accomplished critical advantages from a broad ability and a
                perceived skill. Throughout the long term, we have applied
                essential instruments and association to the production of OEM
                and Cobranded oils, in complete attentiveness.
              </p>
              <p>
                On the off chance that you seek after expanded lubricants deals
                and benefits, building brand mindfulness, holding clients and
                drawing in new ones, private labeled is your answer. With the
                help of our specialized technical ability and a total scope of
                administrations, setting up and developing your own image of
                brand will be made fundamentally simpler.
              </p>
            </div>
          </Container>
        </section>
        <section className="layout-padding2-bottom">
          <Container>
            <div
              className="partnership-detail-container"
              data-sal-duration="1000"
              data-sal="slide-up"
              data-sal-easing="ease"
            >
              <h3 className="section-heading mb-4">
                YOU GIVE YOUR CUSTOMERS MORE REASONS TO BUY FROM YOU
              </h3>
              <p>
                Your privately labeled products offer your clients and
                possibilities various advantages:
              </p>
              <ul>
                <li>
                  More prominent opportunity of decision from one advantageous
                  source.
                </li>
                <li>
                  A line of items that compare with their specific requirements.
                </li>
                <li>Selective items that they won't find somewhere else.</li>
                <li>
                  Equivalent quality with better reserve funds and esteem, or
                  more prominent quality at a serious or somewhat more
                  exorbitant cost (contingent upon your marketing approach).
                </li>
                <li>
                  Items that they can purchase with certainty, so they can stop
                  correlation shopping and basically keep purchasing from you.
                </li>
              </ul>
              <h3 className="section-heading mb-4 mt-5">
                YOU GIVE YOUR CUSTOMERS MORE REASONS TO BUY FROM YOU
              </h3>
              <p>
                By giving those advantages through private labeling, you may:
              </p>
              <ul>
                <li>
                  Fabricate brand mindfulness by showing your image name and
                  picture on your line of selective items.
                </li>
                <li>
                  Draw in new clients and reinforce associations with existing
                  ones.
                </li>
                <li>
                  Urge client dedication to purchase your private-label items
                  more than once over the long haul.
                </li>
              </ul>
              <p>
                To put it plainly, private labeling naming offers you a
                strategic advantage, assisting you with acquiring a bigger
                portion of the overall industry and develop your business.
              </p>
            </div>
          </Container>
        </section>
        <section className="layout-padding-bottom">
          <Container>
            <div
              className="partnership-detail-container"
              data-sal-duration="1000"
              data-sal="slide-up"
              data-sal-easing="ease"
            >
              <h3 className="section-heading mb-4 ">YOU ARE IN CONTROL</h3>
              <p>
                Private marking offers you various choices and freedoms to build
                up and deal with your program as you see fit.
              </p>
              <ul>
                <li>Your own look and message</li>
                <li>Arrangement with your promoting procedure</li>
                <li>Expanded benefit potential</li>
                <li>Adaptability</li>
              </ul>
              <p>
                We have our own Container fabricating unit introduced in our
                processing plant to help our consistent production. It
                additionally provides us with the upside of offering wide scope
                of currently accessible standard form plans and surprisingly
                custom designed molds dependent on individual branding plan and
                Colour prerequisites.
              </p>
            </div>
          </Container>
        </section>
      </LayoutAnimate>
    </Fragment>
  )
}

export default About
